import React, { useEffect } from 'react';
import { BaseTemplate } from '@templates';
import ShareBar from '@components/share_bar/ShareBar';
import { SEO } from '@components';

import '@style/base.scss';
import '@style/grid.scss';

import './post.scss';
import PublishedBar from '../../components/published_bar/PublishedBar';

export default ({ pageContext, location }) => {
  useEffect(() => {
    document.querySelectorAll('.post-section img').forEach(elem => {
      // elem.removeAttribute('srcset');

      if (elem.src.includes('data:image/svg+xml')) {
        elem.src = elem.getAttribute('data-orig-src');
      }
    });
  }, []);

  const seo = {
    title: pageContext.seo === null ? null : pageContext.seo.title,
    description: pageContext.seo === null ? null : pageContext.seo.description
  };

  return (
    <BaseTemplate>
      <SEO title={seo.title} image={pageContext.featuredImage} description={seo.description} />
      <div className="page-section post">
        <h1 className="post-title">{pageContext.title}</h1>
        <div
          className="post-section"
          dangerouslySetInnerHTML={{
            __html: pageContext.content
          }}
        />
        <div className="post-footer">
          <ShareBar url={location.href} title={pageContext.title} />
          <PublishedBar publishedDate={pageContext.publishedDate} />
        </div>
      </div>
    </BaseTemplate>
  );
};
