import React from 'react';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';
import './ShareBar.scss';

export default ({ title, url }) => {
  return (
    <div className="share-bar">
      <h3 className="heading">Share this story</h3>
      <div className="social-buttons-container">
        <EmailShareButton url={url} subject={title}>
          <EmailIcon size="40" borderRadius={12} />
        </EmailShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon size="40" borderRadius={12} />
        </FacebookShareButton>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size="40" borderRadius={12} />
        </LinkedinShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon size="40" borderRadius={12} />
        </TwitterShareButton>
        <WhatsappShareButton url={url}>
          <WhatsappIcon size="40" borderRadius={12} />
        </WhatsappShareButton>
      </div>
    </div>
  );
};
