import React from 'react';

import './PublishedBar.scss';

export default ({ publishedDate }) => {
  return (
    <div className="published-bar">
      Published On:
      {publishedDate}
    </div>
  );
};
